import { Route, Routes, useLocation } from "react-router-dom";
import { useLayoutEffect } from "react";
import Home from "./Pages/Home/Home";
import Contact_Us from "./Pages/Contact/Contact_Us";
import General_FAQ from "./Pages/FAQ/General_FAQ";
import Onboarding_FAQ from "./Pages/FAQ/Onboarding_FAQ";
import Data_Safety from "./Pages/Data_Safety/Data_Safety";
import Help from "./Pages/Help/Help";
import Onboarding from "./Pages/Onboarding/Onboarding";
import Livenesscheck from "./Pages/Onboarding/Livenesscheck";
import Finish from "./Pages/Onboarding/Finish";
import Error from "./Pages/Error";
import Complaint_Policy from "./Pages/Complaint_Policy/Complaint_Policy";
import Privacy_Policy from "./Pages/Privacy_Policy/Privacy_Policy";
import Terms_and_Conditions from "./Pages/Terms_and_Conditions/Terms_and_Conditions";
import Money_Transfer_to_Ghana from "./Pages/Money_Transfer/Money_Transfer_to_Ghana";
import Money_Transfer_to_Gambia from "./Pages/Money_Transfer/Money_Transfer_to_Gambia";
import Money_Transfer_to_Senegal from "./Pages/Money_Transfer/Money_Transfer_to_Senegal";

function App() {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="App">
  
      <Routes>
      <Route path="/*" element={<Error />} /> 
      <Route path="/" element={<Home />} /> 
      <Route path="/money-transfer-to-ghana" element={<Money_Transfer_to_Ghana />} /> 
      <Route path="/money-transfer-to-gambia" element={<Money_Transfer_to_Gambia />} /> 
      <Route path="/money-transfer-to-senegal" element={<Money_Transfer_to_Senegal />} />
      <Route path="/terms-and-conditions" element={<Terms_and_Conditions />} /> 
      <Route path="/privacy-policy" element={<Privacy_Policy />} /> 
      <Route path="/complaints-policy" element={<Complaint_Policy />} /> 
      <Route path="/help" element={<Help />} /> 
      <Route path="/onboarding" element={<Onboarding />} />
      <Route path="/livenesscheck" element={<Livenesscheck />} />
      <Route path="/finish" element={<Finish />} />
      <Route path="/general-faq" element={<General_FAQ />} /> 
      <Route path="/onboarding-faq" element={<Onboarding_FAQ />} />
      <Route path="/data-safety" element={<Data_Safety />} />
      <Route path="/contact-us" element={<Contact_Us />} /> 
      </Routes>
      
    </div>
  );
}

export default App;
