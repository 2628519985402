import React from 'react'

function Preloader() {
    return (
        <div>
            {/* Preloader Section Here */}
            <div className="preloader">
                <div className="loader4" />
            </div>

            {/* Preloader Section End */}

        </div>
    )
}

export default Preloader
