import React, { useEffect, useState, useRef } from 'react';
import Preloader from '../../Components/Preloader';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import { NavLink } from 'react-router-dom';
import General_FAQ_Seo from '../../SEO/General_FAQ_Seo';

function General_FAQ() {
  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(null);
  const contentRefs = useRef([]);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  return (
    <div>
      {isLoading ? (
        <Preloader />
      ) : (
        <div>
          <General_FAQ_Seo/>
          <Header />
          <section className="p-5" style={{ backgroundImage: 'url(assets/img/hero-three.jpg)' }}>
            <div className="container">
              <div className="row align-items-end">
                <div className="col-lg-6">
                  <div className="bannr-text">
                    <h2>General FAQs</h2>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="gap">
            <div className="container">
              <div className="row align-items-center mb-5">
                <div className="col-lg-8">
                  <div className="heading two">
                    <h6>Our FAQ's</h6>
                    <h2>Frequently Asked Questions</h2>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <div className="accordion two">
                    <div className={`accordion-item ${activeIndex === 0 ? 'active' : ''}`}>
                      <NavLink to="javascript:void(0)" className="heading" onClick={() => toggleAccordion(0)}>
                        <div className="icon" />
                        <div className="title">Why do you need my documents while registration?</div>
                      </NavLink>
                      <div
                        className="content"
                        ref={(el) => (contentRefs.current[0] = el)}
                        style={{
                          maxHeight: activeIndex === 0 ? contentRefs.current[0]?.scrollHeight : 0,
                          overflow: 'hidden',
                          transition: 'max-height 300ms ease',
                        }}
                      >
                        <p>We are regulated by Financial Conduct Authority in the UK. It is our legal binding to verify your identity as a UK resident.</p>
                      </div>
                    </div>

                    <div className={`accordion-item ${activeIndex === 1 ? 'active' : ''}`}>
                      <NavLink to="javascript:void(0)" className="heading" onClick={() => toggleAccordion(1)}>
                        <div className="icon" />
                        <div className="title">How can I cancel my transfer?</div>
                      </NavLink>
                      <div
                        className="content"
                        ref={(el) => (contentRefs.current[1] = el)}
                        style={{
                          maxHeight: activeIndex === 1 ? contentRefs.current[1]?.scrollHeight : 0,
                          overflow: 'hidden',
                          transition: 'max-height 300ms ease',
                        }}
                      >
                        <p>Please call us or email us at earliest possible if you want to cancel your transfer. Transfer paid out to the recipient cannot be cancelled. If you have paid for your transfer and the transfer is not processed yet, then please contact us by phone and we will make necessary arrangements for refunds. We may charge you a small fee as handling/service charge and refund you the balance amount.</p>
                      </div>
                    </div>

                    <div className={`accordion-item ${activeIndex === 2 ? 'active' : ''}`}>
                      <NavLink to="javascript:void(0)" className="heading" onClick={() => toggleAccordion(2)}>
                        <div className="icon" />
                        <div className="title">Will I get notifications about Transfer status?</div>
                      </NavLink>
                      <div
                        className="content"
                        ref={(el) => (contentRefs.current[2] = el)}
                        style={{
                          maxHeight: activeIndex === 2 ? contentRefs.current[2]?.scrollHeight : 0,
                          overflow: 'hidden',
                          transition: 'max-height 300ms ease',
                        }}
                      >
                        <p>Yes. We send email notifications to keep you informed. You can even view the transfer status by signing in into your web account.</p>
                      </div>
                    </div>

                    <div className={`accordion-item ${activeIndex === 3 ? 'active' : ''}`}>
                      <NavLink to="javascript:void(0)" className="heading" onClick={() => toggleAccordion(3)}>
                        <div className="icon" />
                        <div className="title">How many recipients I can add?</div>
                      </NavLink>
                      <div
                        className="content"
                        ref={(el) => (contentRefs.current[3] = el)}
                        style={{
                          maxHeight: activeIndex === 3 ? contentRefs.current[3]?.scrollHeight : 0,
                          overflow: 'hidden',
                          transition: 'max-height 300ms ease',
                        }}
                      >
                        <p>There is no restriction on the number of recipients you may have on your account.</p>
                      </div>
                    </div>

                    <div className={`accordion-item ${activeIndex === 4 ? 'active' : ''}`}>
                      <NavLink to="javascript:void(0)" className="heading" onClick={() => toggleAccordion(4)}>
                        <div className="icon" />
                        <div className="title">You asked me to upload one more document; how can I do that?</div>
                      </NavLink>
                      <div
                        className="content"
                        ref={(el) => (contentRefs.current[4] = el)}
                        style={{
                          maxHeight: activeIndex === 4 ? contentRefs.current[4]?.scrollHeight : 0,
                          overflow: 'hidden',
                          transition: 'max-height 300ms ease',
                        }}
                      >
                        <p>Please login in to your account and under My Account you will find document section where you can upload the document.</p>
                      </div>
                    </div>

                    <div className={`accordion-item ${activeIndex === 5 ? 'active' : ''}`}>
                      <NavLink to="javascript:void(0)" className="heading" onClick={() => toggleAccordion(5)}>
                        <div className="icon" />
                        <div className="title">How can I pay for my transfer? </div>
                      </NavLink>
                      <div
                        className="content"
                        ref={(el) => (contentRefs.current[5] = el)}
                        style={{
                          maxHeight: activeIndex === 5 ? contentRefs.current[5]?.scrollHeight : 0,
                          overflow: 'hidden',
                          transition: 'max-height 300ms ease',
                        }}
                      >
                        <p>Bank Transfer to our business account and payments made by using credit or debit cards. Bank Transfer is the recommended and preferred method of payment. There may be restrictions on credit and debit card payment as well as there may be a fee charged on top.</p>
                      </div>
                    </div>

                    <div className={`accordion-item ${activeIndex === 6 ? 'active' : ''}`}>
                      <NavLink to="javascript:void(0)" className="heading" onClick={() => toggleAccordion(6)}>
                        <div className="icon" />
                        <div className="title">Why I received email stating transfer complete, but the money is not with recipient?</div>
                      </NavLink>
                      <div
                        className="content"
                        ref={(el) => (contentRefs.current[6] = el)}
                        style={{
                          maxHeight: activeIndex === 6 ? contentRefs.current[6]?.scrollHeight : 0,
                          overflow: 'hidden',
                          transition: 'max-height 300ms ease',
                        }}
                      >
                        <p>Our pay-out partner bank/agent may have completed the transfer process but the recipient's bank would not have displayed it in their account. Please allow couple of hours to be displayed, if the problem persists then please contact our support team. We will make sure money is with your recipient as promised.</p>
                      </div>
                    </div>

                    <div className={`accordion-item ${activeIndex === 7 ? 'active' : ''}`}>
                      <NavLink to="javascript:void(0)" className="heading" onClick={() => toggleAccordion(7)}>
                        <div className="icon" />
                        <div className="title">Good to know </div>
                      </NavLink>
                      <div
                        className="content"
                        ref={(el) => (contentRefs.current[7] = el)}
                        style={{
                          maxHeight: activeIndex === 7 ? contentRefs.current[7]?.scrollHeight : 0,
                          overflow: 'hidden',
                          transition: 'max-height 300ms ease',
                        }}
                      >
                        <ul className="list">
                          <li><i className="fa-solid fa-square-check" /> Age: 18 years and above</li>
                          <li><i className="fa-solid fa-square-check" /> We will verify your identity, this will not affect your credit ratings</li>
                          <li><i className="fa-solid fa-square-check" /> You must be resident of the UK</li>
                          <li><i className="fa-solid fa-square-check" /> Activation of account will be based on the documents you provide</li>
                          <li><i className="fa-solid fa-square-check" /> Monthly sending limits may apply</li>
                        </ul>
                      </div>
                    </div>

                    <div className={`accordion-item ${activeIndex === 8 ? 'active' : ''}`}>
                      <NavLink to="javascript:void(0)" className="heading" onClick={() => toggleAccordion(8)}>
                        <div className="icon" />
                        <div className="title">Documents Required</div>
                      </NavLink>
                      <div
                        className="content"
                        ref={(el) => (contentRefs.current[8] = el)}
                        style={{
                          maxHeight: activeIndex === 8 ? contentRefs.current[8]?.scrollHeight : 0,
                          overflow: 'hidden',
                          transition: 'max-height 300ms ease',
                        }}
                      >
                        <ul class="list">
                          <li><i class="fa-solid fa-square-check"></i> Photo Identity Proof (Passport, EU Nationality card or UK Driving License).</li>
                          <li><i class="fa-solid fa-square-check"></i> Address Proof (Utility bill, Bank Statement, Council Tax bill not less than 3 months old).</li>
                          <li><i class="fa-solid fa-square-check"></i> Source of funds document.</li>
                          <li><i class="fa-solid fa-square-check"></i> Company Houses Registration (mandatory for Corporate Accounts).</li>
                          <li><i class="fa-solid fa-square-check"></i> Company Address Proof (mandatory for Corporate Accounts).</li>
                          <li><i class="fa-solid fa-square-check"></i> Company Bank Statement (mandatory for Corporate Accounts).</li>
                        </ul>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      )}
    </div>
  );
}

export default General_FAQ;
