import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Preloader from '../../Components/Preloader';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Help_Seo from '../../SEO/Help_Seo';

function Help() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    }, []);

    return (
        <div>
            {isLoading ? (
                <Preloader />
            ) : (
                <div>
                    <Help_Seo/>
                <Header />
                <section className="p-5" style={{ backgroundImage: 'url(assets/img/hero-three.jpg)' }}>
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-6">
                                <div className="bannr-text">
                                    <h2>Help</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="gap meet-section">
                    <div className="container">
                        <div className="row align-items-center pl-80">
                            <div className="col-lg-4 col-md-6">
                                <NavLink to='/onboarding'>
                                <div className="location mt-3">
                                    <i>
                                    <img src="assets/img/photo/kyc.png" alt="KYC Upload"/>
                                    </i>
                                    <h3>KYC Upload</h3>
                                </div>
                                </NavLink>                               
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <NavLink to='/data-safety'>
                                <div className="location mt-3">
                                    <i>
                                    <img src="assets/img/photo/data-safety.png" alt="Data Safety"/>
                                    </i>
                                    <h3>Data Safety</h3>
                                </div>
                                </NavLink>                               
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <NavLink to='/onboarding-faq'>
                                <div className="location mt-3">
                                    <i>
                                    <img src="assets/img/photo/onbordingfaq.png" alt="Onboarding FAQs"/>
                                    </i>
                                    <h3>Onboarding FAQs</h3>
                                </div>
                                </NavLink>                               
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <NavLink to='/general-faq'>
                                <div className="location mt-3">
                                    <i>
                                    <img src="assets/img/photo/faq.png" alt="General FAQs"/>
                                    </i>
                                    <h3>General FAQs</h3>
                                </div>
                                </NavLink>                               
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <NavLink to='/contact-us'>
                                <div className="location mt-3">
                                    <i>
                                    <img src="assets/img/photo/contactinfo.png" alt="Contact Info"/>
                                    </i>
                                    <h3>Contact Info</h3>
                                </div>
                                </NavLink>                               
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
            )}
        </div>
    );
}

export default Help
