import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Preloader from '../Components/Preloader';
import Error_Seo from '../SEO/Error_Seo';


function Error() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    }, []);

    return (
        <div>
            {isLoading ? (
                <Preloader />
            ) : (
                <div>
                    <Error_Seo/>
                    <section className="gap">
                        <div className="container">
                            <div className="row align-items-center justify-content-center">   
                                <div className="col-lg-12 text-center">
                                <div className="together-img">
                                        <img src="assets/img/404.png" alt="Together" style={{maxWidth:'90%'}}/>
                                    </div>
                                    <div className="together text-center mt-5">
                                        <h2>Opps! Page not found</h2>
                                        <p className="text-center" style={{width:'100%'}}>We're sorry, the page you have looked for does not exist in our database! Maybe go to our home page or try to use a search? </p>
                                        <NavLink to="/" className="btn mt-3">Go to Home</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            )}
        </div>
    );
}

export default Error
