import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

function Footer() {
    const [scrollPercentage, setScrollPercentage] = useState(0);

    // Function to calculate scroll percentage
    const calculateScrollPercentage = () => {
        const scrollTop = window.scrollY;
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;

        const totalScroll = documentHeight - windowHeight;
        const scrollPercentage = (scrollTop / totalScroll) * 100;

        return scrollPercentage;
    };

    // Update scroll percentage on scroll
    const handleScroll = () => {
        const percentage = calculateScrollPercentage();
        setScrollPercentage(percentage);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Style object for the progress bar
    const progressBarStyle = {
        display: scrollPercentage > 0 ? 'grid' : 'none',
        background: `conic-gradient(rgb(239, 91, 0) ${scrollPercentage}%, rgb(255, 255, 255) ${scrollPercentage}%)`
    };

    // Scroll to top function
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div>
            <footer style={{ backgroundImage: 'url(assets/img/footer.jpg)' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="information">
                                <div className="d-flex mb-4">
                                    <i className="fa-solid fa-location-dot" />
                                    <h6>St. James Tower, 7 Charlotte Street, Manchester, United Kingdom, M1 4DZ</h6>
                                </div>
                                <div className="d-flex mb-4">
                                    <i className="fa-solid fa-envelope"></i>
                                    <h6><NavLink to='mailto:info@sendtango.com'>info@sendtango.com</NavLink></h6>
                                </div>
                                <div className="d-flex">
                                    <i className="fa-solid fa-phone"></i>
                                    <h6><NavLink to='tel:447814819721'>+44 7814 819721</NavLink></h6>
                                </div>
                                <ul className="social-icon">
                                    <li><NavLink to="#"><i className="fa-brands fa-facebook-f" /></NavLink></li>
                                    <li><NavLink to="#"><i className="fa-brands fa-twitter" /></NavLink></li>
                                    <li><NavLink to="#"><i className="fa-brands fa-google" /></NavLink></li>
                                    <li><NavLink to="#"><i className="fa-brands fa-instagram" /></NavLink></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-links">
                                <h3>Quick Links</h3>
                                <div className="boder" />
                                <ul>
                                    {/* <li><i className="fa-solid fa-angle-right" /><NavLink to="javascript:void(0)">About Us</NavLink></li> */}
                                    <li><i className="fa-solid fa-angle-right" /><NavLink to="/help">Help</NavLink></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-links">
                                <h3>Legal links</h3>
                                <div className="boder" />
                                <ul>
                                    <li><i className="fa-solid fa-angle-right" /><NavLink to="/terms-and-conditions">Terms &amp; Conditions</NavLink></li>
                                    <li><i className="fa-solid fa-angle-right" /><NavLink to="/privacy-policy">Privacy Policy</NavLink></li>
                                    <li><i className="fa-solid fa-angle-right" /><NavLink to="/complaints-policy">Complaint Policy</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="copyrights">
                        <div className="d-lg-flex align-items-center">
                            <NavLink to="/"><img src="assets/img/logo/footer-logo.png" alt="SendTango" /></NavLink>
                            <div className="boder d-none d-sm-block d-sm-none d-md-block" />
                            <p>
                            Send Tango is Registered with Companies House with name Top Connect Ltd and registration number 08659511. We are regulated by FCA with FRN 610825.
                            </p>
                        </div>
                        <p>
                            Copyright ©<script type="text/javascript ">document.write(new Date().getFullYear());</script>
                            <NavLink to="/">SendTango </NavLink>
                            All Rights Reserved.&ensp;|&ensp;Powered By&ensp;
                            <NavLink to="https://www.calyx-solutions.com/ " target="_blank ">Calyx Solutions.</NavLink>
                        </p>
                    </div>
                </div>
            </footer>

            {/* Progress bar */}
            <div id="progress" style={progressBarStyle} onClick={scrollToTop}>
                <span id="progress-value"><i className="fa-solid fa-up-long" /></span>
            </div>
        </div>
    );
}

export default Footer;
