import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

function Header() {
    const [isOpen, setIsOpen] = useState(false);
    const [activeMenu, setActiveMenu] = useState(null);
    const [showCookiesConsent, setShowCookiesConsent] = useState(true);
    const handleMenuClick = () => {
        setIsOpen(true);
    };

    const handleCloseClick = () => {
        setIsOpen(false);
    };

    const handleDropdownClick = (event) => {
        const menuItem = event.currentTarget;
        const isActive = menuItem.classList.contains('active');

        // If clicked menu item is already active, close it
        if (isActive) {
            menuItem.classList.remove('active');
            setActiveMenu(null);
        } else {
            // Remove 'active' class from previously active menu item
            if (activeMenu) {
                activeMenu.classList.remove('active');
            }

            // Add 'active' class to the clicked menu item
            menuItem.classList.add('active');
            setActiveMenu(menuItem);
        }
    };
    useEffect(() => {
        const cookiesAccepted = localStorage.getItem("cookiesAccepted");
        if (cookiesAccepted) {
          setShowCookiesConsent(false);
        }
    }, [])
    const acceptCookies = () => {
        localStorage.setItem("cookiesAccepted", "true");
        setShowCookiesConsent(false);
      };

    return (
        <div>
            <header className="topnav two">
                <div className="container">
                    <div className="header-bar">
                        <NavLink to="/"><img src="assets/img/logo/logo.png" alt="logo" /></NavLink>
                        <nav className="navbar">
                            <ul className="navbar-links">
                                <li className="navbar-dropdown has-children" onClick={handleDropdownClick}>
                                    <NavLink to="#">Money Transfer</NavLink>
                                    <ul className="dropdown">
                                        <li><NavLink to="/money-transfer-to-ghana"><i className="fa-solid fa-arrow-right" />Money Transfer to Ghana</NavLink></li>
                                        <li><NavLink to="/money-transfer-to-gambia"><i className="fa-solid fa-arrow-right" />Money Transfer to Gambia</NavLink></li>
                                        <li><NavLink to="/money-transfer-to-senegal"><i className="fa-solid fa-arrow-right" />Money Transfer to Senegal</NavLink></li>
                                    </ul>
                                </li>
                                <li className="navbar-dropdown" onClick={handleDropdownClick}>
                                    <NavLink to="/help">Help</NavLink>
                                </li>
                            </ul>
                        </nav>
                        <div className="pickup">
                            <NavLink to="https://sendtango.com/app/sign-up" target='_blank' className="btn2">Signup</NavLink>
                            <NavLink to="https://sendtango.com/app" target='_blank' className="btn">Login</NavLink>
                        </div>
                        <div className="bar-menu" onClick={handleMenuClick}>
                            <i className="fa-solid fa-bars" />
                        </div>
                    </div>
                    <div className={`mobile-nav hmburger-menu ${isOpen ? 'open' : ''}`} id="mobile-nav">
                        <div className="res-log">
                            <NavLink to="/">
                                <img src="assets/img/logo/logo.png" alt="Responsive Logo" />
                            </NavLink>
                        </div>
                        <ul>
                            <li className="menu-item-has-children" onClick={handleDropdownClick}>
                                <NavLink to="JavaScript:void(0)">Money Transfer<i className="fa-solid fa-angle-right" /></NavLink>
                                <ul className="sub-menu">
                                    <li><NavLink to="/money-transfer-to-ghana">Money Transfer to Ghana</NavLink></li>
                                    <li><NavLink to="/money-transfer-to-gambia">Money Transfer to Gambia</NavLink></li>
                                    <li><NavLink to="/money-transfer-to-senegal">Money Transfer to Senegal</NavLink></li>
                                </ul>
                            </li>
                            <li><NavLink to="/help">Help</NavLink></li>
                            <li><NavLink to="/https://sendtango.com/app/sign-up" target='_blank'>Signup</NavLink></li>
                        </ul>

                        <div className='text-center'>
                            <NavLink to="https://sendtango.com/app" className="btn" target='_blank' style={{ padding: '14px 95px' }}>Login</NavLink>
                        </div>

                        <div className="menu-sidebar-single-widget">
                            <h5 className="menu-sidebar-title">Contact Info</h5>
                            <div className="header-contact-info">
                                <span><i className="fa-solid fa-location-dot" />St. James Tower, 7 Charlotte Street, Manchester, United Kingdom, M1 4DZ</span>
                                <span>
                                    <NavLink to="mailto:info@sendtango.com">
                                        <i className="fa-solid fa-envelope" />info@sendtango.com
                                    </NavLink>
                                </span>
                                <span><NavLink to="tel:447814819721"><i className="fa-solid fa-phone" />+44 7814 819721</NavLink></span>
                            </div>
                            {/* <div className="social-profile">
                                <NavLink to="#"><i className="fa-brands fa-facebook-f" /></NavLink>
                                <NavLink to="#"><i className="fa-brands fa-twitter" /></NavLink>
                                <NavLink to="#"><i className="fa-brands fa-linkedin-in" /></NavLink>
                                <NavLink to="#"><i className="fa-brands fa-youtube" /></NavLink>
                            </div> */}
                        </div>
                        <NavLink to="JavaScript:void(0)" id="res-cross" onClick={handleCloseClick}><i className="fa-regular fa-circle-xmark" /></NavLink>
                    </div>
                </div>
            </header>
            {showCookiesConsent && (
        <div className="wrapper">
        <div className="d-flex align-items-center justify-content-start gap-2">
        <img src="../images/cookies.png" alt="cookies consent" />
        <h5>Cookies Consent</h5>
        </div>
          <div className="content">
        
            <p>
              We use cookies to improve your browsing experience and for marketing purposes.
            </p>
            <div >
              <button className="thm-btn" onClick={acceptCookies}>
                <span className='txt'>I understand</span>
              </button>
              <NavLink to="/privacy-policy" className="item ms-3" style={{textDecoration: 'underline'}}>
                Privacy Policy
              </NavLink>
            </div>
          </div>
        </div>
      )}
        </div>
    );
}

export default Header;
