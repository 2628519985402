import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import countryPageMapping from './CountryMap.json'; // Assuming CountryMap.json is in the same 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ApiConfig from './ApiConfig';
import { NavLink } from 'react-router-dom';
import countryIdPageMapping from './CountryIdMap.json'
function Calculator() {
  const [countries, setCountries] = useState([]);
  var pageid = countryIdPageMapping[window.location.pathname.replaceAll("/","")];
  console.log(pageid);
  if(pageid == undefined){
   pageid = '104'

  }     
  const [selectedCountry, setSelectedCountry] = useState(pageid); // Default selected country ID
  const [selectedBranchId, setSelectedBranchId] = useState('2');
  const [selectedCountryDetails, setSelectedCountryDetails] = useState(null);
  const [currencyList, setCurrencyList] = useState([]);
  const [amount, setAmount] = useState('100.00');
  const [rate, setRate] = useState('');
  const [currencyCode, setCurrencyCode] = useState('');
  const [fees, setFees] = useState('');
  const [calculatedAmount, setCalculatedAmount] = useState('');
  const [collectionTypes, setCollectionTypes] = useState([]);
  const [selectedCollectionType, setSelectedCollectionType] = useState('');
  const [selectedCollectionTypeName, setSelectedCollectionTypeName] = useState('');
  const [deliveryTypes, setDeliveryTypes] = useState([]);
  const [selectedDeliveryType, setSelectedDeliveryType] = useState('');
  const [selectBasecountryId, setselectBasecountryId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const baseUrl = '../ApiConfig.js'
  const navigate = useNavigate();
  const location = useLocation();
  const amountTimeoutRef = useRef(null);
  const baseCountryFlags = {
    1: 'assets/img/flags/gbp.png',
    6: 'assets/img/flags/eur.png',
    17: 'assets/img/flags/cad.png',
  };
  const [baseCountryFlag, setBaseCountryFlag] = useState(baseCountryFlags['1']);

  // Parse URL to set the selected country

  useEffect(() => {
    const pathParts = location.pathname.split('_');
    if (pathParts.length > 1) {
      const countryFromURL = pathParts[1];
      const matchedCountry = countries.find(country => country.countryName.toLowerCase() === countryFromURL.toLowerCase());
      if (matchedCountry) {
        setSelectedCountry(matchedCountry.countryID.toString());
        setSelectedCountryDetails(matchedCountry);
        setCurrencyCode(matchedCountry.countryCurrency);
      }
    }
  }, [location.pathname, countries]);

  // Fetch countries on component mount
  useEffect(() => {
    axios.post(`${ApiConfig.baseUrl}/checkrateslistcountry/checklistcountry`, {
      clientID: '1',
    })
      .then((response) => {
        if (response.data.response && response.data.data) {
          setCountries(response.data.data);
          const defaultCountryDetails = response.data.data.find(
            (country) => country.countryID === parseInt(selectedCountry, 10)
          );
          setSelectedCountryDetails(defaultCountryDetails);
          // if (defaultCountryDetails) {
          //     setCurrencyCode(defaultCountryDetails.countryCurrency);
          // }
        }
      })
      .catch((error) => {
        console.error('Error fetching countries:', error);
      });
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedCountry && selectedBranchId) {
      axios.post(`${ApiConfig.baseUrl}/currency/currencylist`, {
        countryID: selectedCountry,
        clientID: '1',
      })
        .then((response) => {
          if (response.data.response && response.data.data) {
            setCurrencyList(response.data.data);
            const firstCurrency = response.data.data[0];
            if (firstCurrency) {
              setCurrencyCode(firstCurrency.currencyCode);
            }
          }
        })
        .catch((error) => {
          console.error('Error fetching currency list:', error);
        });
    }
  }, [selectedCountry, selectedBranchId]);

  useEffect(() => {
    axios.post(`${ApiConfig.baseUrl}/collection/getcollectiontype`, {
      clientID: '1',
      branchID: selectedBranchId,
      countryID: selectedCountry,
    })
      .then((response) => {
        if (response.data.response && response.data.data) {
          setCollectionTypes(response.data.data);
          const defaultCollectionType = response.data.data[0];
          if (defaultCollectionType) {
            setSelectedCollectionType(defaultCollectionType.paymentCollectionTypeID.toString());
            setSelectedCollectionTypeName(defaultCollectionType.typeName);
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching collection types:', error);
      });
  }, [selectedCountry, selectedBranchId]);

  useEffect(() => {
    axios.post(`${ApiConfig.baseUrl}/deliverytype/getdeliverytype`, {
      clientID: '1',
      branchID: selectedBranchId,
      countryID: selectedCountry,
    })
      .then((response) => {
        if (response.data.response && response.data.data) {
          setDeliveryTypes(response.data.data);
          const defaultDeliveryType = response.data.data[0];
          if (defaultDeliveryType) {
            setSelectedDeliveryType(defaultDeliveryType.deliveryTypeID.toString());
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching delivery types:', error);
      });
  }, [selectedCountry, selectedBranchId]);

  useEffect(() => {
    if (amount && selectedCountry && currencyCode) {
      if (amountTimeoutRef.current) {
        clearTimeout(amountTimeoutRef.current);
      }
      setIsLoading(true);
      amountTimeoutRef.current = setTimeout(() => {
        axios.post(`${ApiConfig.baseUrl}/checkrateslistcountry/checkrateslistcountry`, {
          clientID: '1',
          countryID: selectedCountry,
          paymentTypeID: "2",
          paymentDepositTypeID: selectedCollectionType,
          deliveryTypeID: selectedDeliveryType,
          transferAmount: amount,
          currencyCode: currencyCode,
          branchID: selectedBranchId,
          BaseCurrencyID: ApiConfig.primaryCurrencyID || "1"
        })
          .then((response) => {
            if (response.data.response && response.data.data && response.data.data.length > 0) {
              let foundRate = false;
              response.data.data.forEach(rateData => {
                const rateValue = rateData.rate;
                const minAmount = rateData.minAmount;
                const maxAmount = rateData.maxAmount;
                if (rateValue !== 0 && amount >= minAmount && amount <= maxAmount) {
                  setRate(rateValue);
                  setFees(rateData.transferFeesGBP);
                  setCalculatedAmount((amount * rateValue).toFixed(2) || '');
                  foundRate = true;
                }
              });
              if (!foundRate) {
                toast.error("Rates And Fees Are Not Available For This Country");
                setAmount('0');
                setCalculatedAmount('0');
              }
            } else {
              toast.error("Rates And Fees Are Not Available For This Country");
              setAmount('0');
              setCalculatedAmount('0');
            }
          })
          .catch((error) => {
            console.error('Error fetching rates and fees:', error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }, 1000);
    }
  }, [amount, selectedCountry, currencyCode, selectedCollectionType, selectedDeliveryType, selectedBranchId, selectBasecountryId]);


  const handleCountryChange = (event) => {
    const newCountryId = event.target.value;
    setSelectedCountry(newCountryId);
    const newCountryDetails = countries.find(country => country.countryID.toString() === newCountryId);
    setSelectedCountryDetails(newCountryDetails);
    if (newCountryDetails) {
      setCurrencyCode(newCountryDetails.countryCurrency);

      // Retrieve the page path from countryPageMapping based on the selected country ID
      const pagePath = countryPageMapping[newCountryId ];
      if (pagePath) {
        // Navigate to the constructed URL path
        navigate(`/${pagePath}`);
      } else {
        // Navigate to a default path if no mapping found (optional)
        navigate('/default-page');
      }
    }
  };

  const handleAmountChange = (event) => {
    let newAmount = event.target.value.replace(/[^\d.]/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2');
    if (newAmount.length > 12) {
      newAmount = newAmount.substring(0, 12);
    }
    setAmount(newAmount);
    setCalculatedAmount((newAmount * rate).toFixed(2) || '');
  };

  const handleCalculatedAmountChange = (event) => {
    let newCalculatedAmount = event.target.value.replace(/[^\d.]/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2');
    if (newCalculatedAmount.length > 12) {
      newCalculatedAmount = newCalculatedAmount.substring(0, 12);
    }
    setCalculatedAmount(newCalculatedAmount);
    setAmount((newCalculatedAmount / rate).toFixed(2) || '');
  };

  const handleAmountFocus = (event) => {
    event.target.value = event.target.value.replace(/,/g, '');
  };

  const handleAmountBlur = (event) => {
    event.target.value = parseFloat(event.target.value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const handleCollectionTypeChange = (event) => {
    const selectedType = event.target.value;
    setSelectedCollectionType(selectedType);
    const selectedTypeName = collectionTypes.find(type => type.paymentCollectionTypeID.toString() === selectedType)?.typeName;
    setSelectedCollectionTypeName(selectedTypeName || '');
  };

  const handleDeliveryTypeChange = (event) => {
    setSelectedDeliveryType(event.target.value);
  };

  const handleBasecountryIdChange = (event) => {
    const newBasecountryId = event.target.value;
    setselectBasecountryId(newBasecountryId);
    setBaseCountryFlag(baseCountryFlags[newBasecountryId]);
  };

  const handleContinue = () => {
    const contactUsPath = `/contact?amount=${calculatedAmount}&rate=${rate}&fees=${fees}&currencyCode=${currencyCode}`;
    navigate(contactUsPath);
  };
  return (
    <div>
      <div className="money-transfer-form">
        <form>
          <div className="form-group">
            <label>Select Country</label>
            <div className="money-transfer-field my-select">
              <select className="my-select-counrty" value={selectedCountry} onChange={handleCountryChange}>
                {countries
                  .filter(country => [195, 104, 101].includes(country.countryID))
                  .map(country => (
                    <option key={country.countryID} value={country.countryID}>
                      {country.countryName}
                    </option>
                  ))}
              </select>
              <div className="amount-currency-select">
                <img className="flagicon ddlcurrency_flag" src={selectedCountryDetails?.flag || selectedCountryDetails?.countryFlag} alt="Currency Flags" />
               
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>You Send</label>
            <div className="money-transfer-field">
              <input className="form-control"
                type="text"
                id="Number1"
                name="send_money"
                value={amount}
                onChange={handleAmountChange}
                onFocus={handleAmountFocus}
                onBlur={handleAmountBlur}
                maxLength={12} // Limiting input to 12 characters
              />
            <div className="amount-currency-select">
                <select onChange={handleBasecountryIdChange}>
                  <option value={1}>GBP</option>
                  {/* <option value={6}>EUR</option>
                  <option value={17}>CAD</option> */}
                </select>
                <img className="flagicon baseflag mt-0" src={baseCountryFlag} alt="Base Country Flag" />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>Recipient gets</label>
            <div className="money-transfer-field">
              <input className="form-control"
                type="text"
                id="Number2"
                name="received_money"
                value={calculatedAmount}
                onChange={handleCalculatedAmountChange}
                onFocus={handleAmountFocus}
                onBlur={handleAmountBlur}
                maxLength={12} // Limiting input to 12 characters
              />
              <div className="amount-currency-select">
                <select>
                  <option>{currencyCode}</option>
                </select>
                <img className="flagicon baseflag mt-0" src={selectedCountryDetails?.flag || selectedCountryDetails?.countryFlag} />
              </div>
            </div>
          </div>
          <div className="currency-info">
            <span><strong>{rate}</strong> Exchange Rate</span>
            <span><strong>{`${fees} GBP`}</strong> Fees</span>
          </div>
          <div className="mt-3">
            <button className="btn" onClick={handleContinue}>
              Continue
            </button>

          </div>
        </form>
      </div>
    </div>
  )
}

export default Calculator
