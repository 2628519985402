import React, { useEffect, useState, useRef } from 'react';
import Preloader from '../../Components/Preloader';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import { NavLink } from 'react-router-dom';
import Calculator from '../../Components/Calculator';
import Money_Transfer_to_Ghana_Seo from '../../SEO/Money_Transfer_to_Ghana_Seo';

function Money_Transfer_to_Ghana() {
    const [isLoading, setIsLoading] = useState(true);
    const [activeIndex, setActiveIndex] = useState(null);
    const contentRefs = useRef([]);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    useEffect(() => {
        // Simulate an asynchronous operation (e.g., fetching data)
        setTimeout(() => {
            setIsLoading(false);
        }, 500); // Simulating a 1-second delay
    }, []);

    return (
        <div>
            {isLoading ? (
                <Preloader />
            ) : (
                <div>
                    <Money_Transfer_to_Ghana_Seo />
                    <Header />
                    {/* Hero Section Here */}
                    <section className="hero-section-two hero-section-two-send-ghana">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-7">
                                    <div className="hero-two-texr text-center">
                                        <h1>Money Transfer to Ghana</h1>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="hero-two-img">
                                        <Calculator />
                                        {/* <img src="assets/img/hero-icon.png" alt="hero-icon.png" className="hero-icon" /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Hero Section End */}
                    <section className="gap">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="propel-img">
                                        <img src="assets/img/money-transfer-to-ghana.png" alt="Propel" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="heading two">
                                        <h6>Fast & Easy way to send money</h6>
                                        <h2>Trustworthy Money Transfer service</h2>
                                    </div>
                                    <div className="propel-text">
                                        <p>Our money transfer service provides a secure, straightforward, and easy-to-understand method for sending money overseas, ensuring its delivery to the intended destination.</p>
                                        <div className="propel">
                                            <div><i><img src="assets/img/icons/competitive-rates.png" alt="Competitive Rates" /></i></div>
                                            <div>
                                                <h5>Competitive Rates</h5>
                                                <p>We offer very transparent and competitive pricing. When you work with us, you save more.</p>
                                            </div>
                                        </div>
                                        <div className="propel">
                                            <div><i><img src="assets/img/icons/safe-secure1.png" alt="Safe and Secure" /></i></div>
                                            <div>
                                                <h5>Safe and Secure</h5>
                                                <p>Advanced privacy and data encryption technology.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* Mobile App Section Start */}
                    <section className="section-mobil" style={{ backgroundImage: 'url(assets/img/footer.jpg)' }}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="img-mobil">
                                        <img src="assets/img/sendtango-app.png" alt="mobil" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="download-app">
                                        <h2>Save Time With The SendTango App</h2>
                                        <p>
                                        In today’s fast-paced world, we understand that every second counts. That’s why we’ve designed our mobile app to make your transfers quicker and more convenient than ever.
                                        </p>
                                        <ul className="list">
                                            <li><img src="assets/img/check.png" alt="check" />Easy sign up.</li>
                                            <li><img src="assets/img/check.png" alt="check" />Save time with Repeat Transfer.</li>
                                            <li><img src="assets/img/check.png" alt="check" />Track your payments in real time.</li>
                                        </ul>
                                        <div className="d-flex align-items-end">
                                            <NavLink to="#"><img src="assets/img/google.png" alt="google" /></NavLink>
                                            <NavLink to="#"><img className="ms-sm-3 mt-sm-0 mt-2" src="assets/img/apple.png" alt="apple" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img src="assets/img/shaps.png" alt="dots-shaps" className="dots-shaps" />
                    </section>
                    {/* Mobile App Section End */}
                    {/* FAQs Section Here */}
                    <section className="gap">
                        <div className="container">
                            <div className="row align-items-center mb-5">
                                <div className="col-lg-8">
                                    <div className="heading two">
                                        <h6>Our FAQ's</h6>
                                        <h2>Frequently Asked Questions</h2>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="d-flex align-items-center phone">
                                        <NavLink to="/general-faq" className="btn">View More</NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-lg-12">
                                    <div className="accordion two">
                                        <div className={`accordion-item ${activeIndex === 0 ? 'active' : ''}`}>
                                            <NavLink to="javascript:void(0)" className="heading" onClick={() => toggleAccordion(0)}>
                                                <div className="icon" />
                                                <div className="title">Why do you need my documents while registration?</div>
                                            </NavLink>
                                            <div
                                                className="content"
                                                ref={(el) => (contentRefs.current[0] = el)}
                                                style={{
                                                    maxHeight: activeIndex === 0 ? contentRefs.current[0]?.scrollHeight : 0,
                                                    overflow: 'hidden',
                                                    transition: 'max-height 300ms ease',
                                                }}
                                            >
                                                <p>We are regulated by Financial Conduct Authority in the UK. It is our legal binding to verify your identity as a UK resident.</p>
                                            </div>
                                        </div>

                                        <div className={`accordion-item ${activeIndex === 1 ? 'active' : ''}`}>
                                            <NavLink to="javascript:void(0)" className="heading" onClick={() => toggleAccordion(1)}>
                                                <div className="icon" />
                                                <div className="title">How can I cancel my transfer?</div>
                                            </NavLink>
                                            <div
                                                className="content"
                                                ref={(el) => (contentRefs.current[1] = el)}
                                                style={{
                                                    maxHeight: activeIndex === 1 ? contentRefs.current[1]?.scrollHeight : 0,
                                                    overflow: 'hidden',
                                                    transition: 'max-height 300ms ease',
                                                }}
                                            >
                                                <p>Please call us or email us at earliest possible if you want to cancel your transfer. Transfer paid out to the recipient cannot be cancelled. If you have paid for your transfer and the transfer is not processed yet, then please contact us by phone and we will make necessary arrangements for refunds. We may charge you a small fee as handling/service charge and refund you the balance amount.</p>
                                            </div>
                                        </div>

                                        <div className={`accordion-item ${activeIndex === 2 ? 'active' : ''}`}>
                                            <NavLink to="javascript:void(0)" className="heading" onClick={() => toggleAccordion(2)}>
                                                <div className="icon" />
                                                <div className="title">Will I get notifications about Transfer status?</div>
                                            </NavLink>
                                            <div
                                                className="content"
                                                ref={(el) => (contentRefs.current[2] = el)}
                                                style={{
                                                    maxHeight: activeIndex === 2 ? contentRefs.current[2]?.scrollHeight : 0,
                                                    overflow: 'hidden',
                                                    transition: 'max-height 300ms ease',
                                                }}
                                            >
                                                <p>Yes. We send email notifications to keep you informed. You can even view the transfer status by signing in into your web account.</p>
                                            </div>
                                        </div>

                                        <div className={`accordion-item ${activeIndex === 3 ? 'active' : ''}`}>
                                            <NavLink to="javascript:void(0)" className="heading" onClick={() => toggleAccordion(3)}>
                                                <div className="icon" />
                                                <div className="title">How many recipients I can add?</div>
                                            </NavLink>
                                            <div
                                                className="content"
                                                ref={(el) => (contentRefs.current[3] = el)}
                                                style={{
                                                    maxHeight: activeIndex === 3 ? contentRefs.current[3]?.scrollHeight : 0,
                                                    overflow: 'hidden',
                                                    transition: 'max-height 300ms ease',
                                                }}
                                            >
                                                <p>There is no restriction on the number of recipients you may have on your account.</p>
                                            </div>
                                        </div>

                                        <div className={`accordion-item ${activeIndex === 4 ? 'active' : ''}`}>
                                            <NavLink to="javascript:void(0)" className="heading" onClick={() => toggleAccordion(4)}>
                                                <div className="icon" />
                                                <div className="title">You asked me to upload one more document; how can I do that?</div>
                                            </NavLink>
                                            <div
                                                className="content"
                                                ref={(el) => (contentRefs.current[4] = el)}
                                                style={{
                                                    maxHeight: activeIndex === 4 ? contentRefs.current[4]?.scrollHeight : 0,
                                                    overflow: 'hidden',
                                                    transition: 'max-height 300ms ease',
                                                }}
                                            >
                                                <p>Please login in to your account and under My Account you will find document section where you can upload the document.</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* FAQs Section End */}
                    {/* Footer Section Here */}
                    <Footer />
                </div>
            )}
        </div>
    );
}

export default Money_Transfer_to_Ghana
