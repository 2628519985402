import React, { useEffect, useState, useRef } from 'react';
import OwlCarousel from 'react-owl-carousel';
import Preloader from '../../Components/Preloader';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import { NavLink } from 'react-router-dom';
import Calculator from '../../Components/Calculator';
import Home_Seo from '../../SEO/Home_Seo';

function Home() {
  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(null);
  const contentRefs = useRef([]);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  useEffect(() => {
    // Simulate an asynchronous operation (e.g., fetching data)
    setTimeout(() => {
      setIsLoading(false);
    }, 500); // Simulating a 1-second delay
  }, []);

  return (
    <div>
      {isLoading ? (
        <Preloader />
      ) : (
        <div>
          <Home_Seo/>
          <Header />
          {/* Hero Section Here */}
          <section className="hero-section-two">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-7">
                  <div className="hero-two-texr">
                    <h1>International Money Transfer</h1>
                    <p>
                    We understand the importance of sending money back home, whether it’s for family support, business, or investment.
                    </p>

                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="hero-two-img">
                    <Calculator />
                    {/* <img src="assets/img/hero-icon.png" alt="hero-icon.png" className="hero-icon" /> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Hero Section End */}
          <div className="no-top mt-4 mb-2">
            <div className="container">
              <div className='row'>
                <div className='col-12'>
                  <div className="trusted-by">
                    <h5 className='pb-0'>Our Corridors</h5>
                    <div className='row justify-content-center mt-3'>
                      <div className="item col-lg-3 col-md-4 col-6">
                        <NavLink to="/money-transfer-to-ghana">
                          <div className="flag-div d-flex align-items-center">
                            <div className="flag-mr">
                              <img className="flag" src="assets/img/flags/ghc.png" alt="Money Transfer to Ghana" />
                            </div>
                            <div className="currency-code">
                              <h4>Ghana</h4>
                            </div>
                          </div>
                        </NavLink>
                      </div>
                      <div className="item col-lg-3 col-md-4 col-6">
                        <NavLink to="/money-transfer-to-gambia">
                          <div className="flag-div d-flex align-items-center">
                            <div className="flag-mr">
                              <img className="flag" src="assets/img/flags/gmd.png" alt="Money Transfer to Gambia" />
                            </div>
                            <div className="currency-code">
                              <h4>Gambia</h4>
                            </div>
                          </div>
                        </NavLink>
                      </div>
                      <div className="item col-lg-3 col-md-4 col-6">
                        <NavLink to="/money-transfer-to-senegal">
                          <div className="flag-div d-flex align-items-center">
                            <div className="flag-mr">
                              <img className="flag" src="assets/img/flags/xof.png" alt="Money Transfer to Senegal" />
                            </div>
                            <div className="currency-code">
                              <h4>Senegal</h4>
                            </div>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*About Section Here */}
          <section className="gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="intelligent">
                    <div className="heading">
                      <h6>About Us</h6>
                      <h2>SendTango</h2>
                    </div>
                    <img src="assets/img/about-sendtango.png" alt="intelligent" />
                    <img src="assets/img/dots-shaps.png" alt="dots-shaps" className="dots-shaps" />
                    <img src="assets/img/shaps-1.png" alt="dots-shaps" className="shaps-1" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="intelligent-text">
                    <p>
                    SendTango is a cutting-edge mobile application designed to simplify and enhance the process of sending money across borders. With its user-friendly interface and advanced features, SendTango enables individuals to securely transfer funds to friends, family, or business partners.
                    </p>
                    <p>
                    Sending money with SendTango is a seamless experience, providing users with a convenient and efficient solution for their international remittance needs. Whether you need to support loved ones abroad, pay for goods and services, or conduct business transactions, SendTango ensures your funds reach the intended recipient swiftly and securely.
                    </p>
                    {/* <div className="mb-5">
                      <NavLink to="/" className="btn">
                        Read More
                      </NavLink>
                    </div> */}
                    <div className="row mt-4">
                      <div className="col-md-6">
                        <i><img src="assets/img/icons/quick-transfer.png" alt="Quick Transfer" /></i>
                        <NavLink to="#"><h4>Quick Transfer</h4></NavLink>
                        <p>
                        We prioritise speed, ensuring your money reaches its destination in minutes, not days.
                        </p>
                      </div>
                      <div className="col-md-6">
                        <i className="two"><img src="assets/img/icons/low-cost.png" alt="Low Cost" /></i>
                        <NavLink to="#"><h4>Low Cost</h4></NavLink>
                        <p>
                        Enjoy competitive rates and low transfer fees, maximising the amount your loved ones receive.
                        </p>
                      </div>
                      <div className="col-md-6">
                        <i><img src="assets/img/icons/safe-secure.png" alt="Safe And Secure" /></i>
                        <NavLink to="#"><h4>Safe And Secure</h4></NavLink>
                        <p>
                        Your security is our top priority. We use advanced encryption technology to protect your personal and financial information.
                        </p>
                      </div>
                      <div className="col-md-6">
                        <i className="two"><img src="assets/img/icons/best-rates.png" alt="Best Rates" /></i>
                        <NavLink to="#"><h4>Best Rates</h4></NavLink>
                        <p>
                        Enjoy some of the most competitive exchange rates in the industry, ensuring your loved ones receive more.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* About Section Here */}
          {/* Steps Section Here */}
          <section className="gap no-top">
            <div className="container">
              <div className="heading">
                <h6>How We Work</h6>
                <h2>You Can Send Money With The Following Four Easy Steps</h2>
              </div>
              <div className="steps-run">
                <div className="steps">
                  <i><img src="assets/img/icons/customer.png" alt="steps" /></i>
                  <span>1</span>
                  <h4>Create An Account</h4>
                  <p>Download our app and signup within minutes. It's easy and free.</p>
                </div>
                <div className="steps">
                  <i><img src="assets/img/icons/transfer.png" alt="steps" /></i>
                  <span>2</span>
                  <h4>Set up a transfer</h4>
                  <p>Add beneficiary and enter an amount to send or amount to receive in foreign currency.</p>
                </div>
                <div className="steps">
                  <i><img src="assets/img/icons/securely.png" alt="steps" /></i>
                  <span>3</span>
                  <h4>Pay securely</h4>
                  <p>Pay using Bank Transfer. This is the most secure and quickest way to receive your payment.</p>
                </div>
                <div className="steps">
                  <i><img src="assets/img/icons/done.png" alt="steps" /></i>
                  <span>4</span>
                  <h4>That's it!</h4>
                  <p>Track your transfer from the app. We payout to beneficiary as promised in delivery method.</p>
                </div>
              </div>
            </div>
          </section>
          {/* Steps Section End */}
          {/* Mobile App Section Start */}
          <section className="section-mobil" style={{ backgroundImage: 'url(assets/img/footer.jpg)' }}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="img-mobil">
                    <img src="assets/img/sendtango-app.png" alt="mobil" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="download-app">
                    <h2>Save Time With The SendTango App</h2>
                    <p>
                    In today’s fast-paced world, we understand that every second counts. That’s why we’ve designed our mobile app to make your transfers quicker and more convenient than ever.
                    </p>
                    <ul className="list">
                      <li><img src="assets/img/check.png" alt="check" />Easy sign up.</li>
                      <li><img src="assets/img/check.png" alt="check" />Save time with Repeat Transfer.</li>
                      <li><img src="assets/img/check.png" alt="check" />Track your payments in real time.</li>
                    </ul>
                    <div className="d-flex align-items-end">
                      <NavLink to="#"><img src="assets/img/google.png" alt="google" /></NavLink>
                      <NavLink to="#"><img className="ms-sm-3 mt-sm-0 mt-2" src="assets/img/apple.png" alt="apple" /></NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img src="assets/img/shaps.png" alt="dots-shaps" className="dots-shaps" />
          </section>
          {/* Mobile App Section End */}
          {/* FAQs Section Here */}
          <section className="gap">
            <div className="container">
              <div className="row align-items-center mb-5">
                <div className="col-lg-8">
                  <div className="heading two">
                    <h6>Our FAQ's</h6>
                    <h2>Frequently Asked Questions</h2>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="d-flex align-items-center phone">
                    <NavLink to="/general-faq" className="btn">View More</NavLink>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <div className="accordion two">
                    <div className={`accordion-item ${activeIndex === 0 ? 'active' : ''}`}>
                      <NavLink to="javascript:void(0)" className="heading" onClick={() => toggleAccordion(0)}>
                        <div className="icon" />
                        <div className="title">Why do you need my documents while registration?</div>
                      </NavLink>
                      <div
                        className="content"
                        ref={(el) => (contentRefs.current[0] = el)}
                        style={{
                          maxHeight: activeIndex === 0 ? contentRefs.current[0]?.scrollHeight : 0,
                          overflow: 'hidden',
                          transition: 'max-height 300ms ease',
                        }}
                      >
                        <p>We are regulated by Financial Conduct Authority in the UK. It is our legal binding to verify your identity as a UK resident.</p>
                      </div>
                    </div>

                    <div className={`accordion-item ${activeIndex === 1 ? 'active' : ''}`}>
                      <NavLink to="javascript:void(0)" className="heading" onClick={() => toggleAccordion(1)}>
                        <div className="icon" />
                        <div className="title">How can I cancel my transfer?</div>
                      </NavLink>
                      <div
                        className="content"
                        ref={(el) => (contentRefs.current[1] = el)}
                        style={{
                          maxHeight: activeIndex === 1 ? contentRefs.current[1]?.scrollHeight : 0,
                          overflow: 'hidden',
                          transition: 'max-height 300ms ease',
                        }}
                      >
                        <p>Please call us or email us at earliest possible if you want to cancel your transfer. Transfer paid out to the recipient cannot be cancelled. If you have paid for your transfer and the transfer is not processed yet, then please contact us by phone and we will make necessary arrangements for refunds. We may charge you a small fee as handling/service charge and refund you the balance amount.</p>
                      </div>
                    </div>

                    <div className={`accordion-item ${activeIndex === 2 ? 'active' : ''}`}>
                      <NavLink to="javascript:void(0)" className="heading" onClick={() => toggleAccordion(2)}>
                        <div className="icon" />
                        <div className="title">Will I get notifications about Transfer status?</div>
                      </NavLink>
                      <div
                        className="content"
                        ref={(el) => (contentRefs.current[2] = el)}
                        style={{
                          maxHeight: activeIndex === 2 ? contentRefs.current[2]?.scrollHeight : 0,
                          overflow: 'hidden',
                          transition: 'max-height 300ms ease',
                        }}
                      >
                        <p>Yes. We send email notifications to keep you informed. You can even view the transfer status by signing in into your web account.</p>
                      </div>
                    </div>

                    <div className={`accordion-item ${activeIndex === 3 ? 'active' : ''}`}>
                      <NavLink to="javascript:void(0)" className="heading" onClick={() => toggleAccordion(3)}>
                        <div className="icon" />
                        <div className="title">How many recipients I can add?</div>
                      </NavLink>
                      <div
                        className="content"
                        ref={(el) => (contentRefs.current[3] = el)}
                        style={{
                          maxHeight: activeIndex === 3 ? contentRefs.current[3]?.scrollHeight : 0,
                          overflow: 'hidden',
                          transition: 'max-height 300ms ease',
                        }}
                      >
                        <p>There is no restriction on the number of recipients you may have on your account.</p>
                      </div>
                    </div>

                    <div className={`accordion-item ${activeIndex === 4 ? 'active' : ''}`}>
                      <NavLink to="javascript:void(0)" className="heading" onClick={() => toggleAccordion(4)}>
                        <div className="icon" />
                        <div className="title">You asked me to upload one more document; how can I do that?</div>
                      </NavLink>
                      <div
                        className="content"
                        ref={(el) => (contentRefs.current[4] = el)}
                        style={{
                          maxHeight: activeIndex === 4 ? contentRefs.current[4]?.scrollHeight : 0,
                          overflow: 'hidden',
                          transition: 'max-height 300ms ease',
                        }}
                      >
                        <p>Please login in to your account and under My Account you will find document section where you can upload the document.</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* FAQs Section End */}
          <section className="gap client-reviews">
            <div className="container">
              <div className="client">
                <div className="client-text">
                  <i className="quote">
                    <img src="assets/img/quote.png" alt="quote" />
                  </i>
                  <OwlCarousel
                    className="client-slider owl-carousel owl-theme"
                    loop
                    margin={10}
                    nav
                    dots
                    items={1}
                    autoplay
                    autoplayTimeout={3000}
                    smartSpeed={1000}
                  >
                    <div className="item">
                      <ul className="star">
                        <li><i className="fa fa-star" /></li>
                        <li><i className="fa fa-star" /></li>
                        <li><i className="fa fa-star" /></li>
                        <li><i className="fa fa-star" /></li>
                        <li><i className="fa fa-star" /></li>
                      </ul>
                      <p>Impression is a great agency to work with and they are always quick to respond to our queries. I truly feel like they are an extension of our in-house team and I'm excited about our partnership.</p>
                      <h4>Thomas Walimes</h4>
                      <span>Marketing Manager</span>
                    </div>
                    <div className="item">
                      <ul className="star">
                        <li><i className="fa fa-star" /></li>
                        <li><i className="fa fa-star" /></li>
                        <li><i className="fa fa-star" /></li>
                        <li><i className="fa fa-star" /></li>
                        <li><i className="fa fa-star" /></li>
                      </ul>
                      <p>Impression is a great agency to work with and they are always quick to respond to our queries. I truly feel like they are an extension of our in-house team and I'm excited about our partnership.</p>
                      <h4>Thomas Walimes</h4>
                      <span>Marketing Manager</span>
                    </div>
                    <div className="item">
                      <ul className="star">
                        <li><i className="fa fa-star" /></li>
                        <li><i className="fa fa-star" /></li>
                        <li><i className="fa fa-star" /></li>
                        <li><i className="fa fa-star" /></li>
                        <li><i className="fa fa-star" /></li>
                      </ul>
                      <p>Impression is a great agency to work with and they are always quick to respond to our queries. I truly feel like they are an extension of our in-house team and I'm excited about our partnership.</p>
                      <h4>Thomas Walimes</h4>
                      <span>Marketing Manager</span>
                    </div>
                    <div className="item">
                      <ul className="star">
                        <li><i className="fa fa-star" /></li>
                        <li><i className="fa fa-star" /></li>
                        <li><i className="fa fa-star" /></li>
                        <li><i className="fa fa-star" /></li>
                        <li><i className="fa fa-star" /></li>
                      </ul>
                      <p>Impression is a great agency to work with and they are always quick to respond to our queries. I truly feel like they are an extension of our in-house team and I'm excited about our partnership.</p>
                      <h4>Thomas Walimes</h4>
                      <span>Marketing Manager</span>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
            </div>
            <img src="assets/img/quote-1.jpg" className="quote-1" alt="img" />
            <img src="assets/img/quote-2.jpg" className="quote-2" alt="img" />
            <img src="assets/img/quote-3.jpg" className="quote-3" alt="img" />
            <img src="assets/img/quote-5.jpg" className="quote-4" alt="img" />
            <img src="assets/img/quote-4.jpg" className="quote-5" alt="img" />
          </section>
          {/* Footer Section Here */}
          <Footer />
        </div>
      )}
    </div>
  );
}

export default Home;
